import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Vision Studio",
      description: "Design & Development",
      imgUrl: projImg1,
      webUrl: "https://www.visionstudios.fi/",
    },
    {
      title: "City Klippet Borås",
      description: "Design & Development",
      imgUrl: projImg2,
      webUrl: "https://cityklippetvandao.com/",
    },
    {
        title: "SnackBox UF",
        description: "Design & Development",
        imgUrl: projImg3
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div>
                <h2>Tjänster</h2>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                <Tab.Container id="projects-tabs" defaultActiveKey="second">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">IT-Support</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Web Utveckling</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">IT-Konsult</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" >
                    <Tab.Pane eventKey="second">
                        <p>Har du precis startat ett nytt företag eller funderar du på att göra det? Något av det viktigaste i dagsläget är en hemsida där du kan nå ut till dina kunder. Vi på J.L Web erbjuder möjligheten att vara med och skapa din helt egna sida med hjälp av våra kunskaper och erfarenheter. Vi kan bygga en sida som använder sig av färdiga strukturer så som Shopify, Wix, M.M. Vill du däremot ha en helt custom hemsida utefter dina behov och krav har vi möjlighet att ta fram sidor i bland annat Next.Js, PHP, Django med mycket mer. Har du frågor kan du kontakta oss för ett kostnadsfritt rådgivningsmöte.</p>
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="first">
                      <p>Har du strul med datorn? Eller vill du bygga en helt ny dator men vet inte hur man gör? Då kan vi hjälpa dig med allt från att skydda dig från virus till att koppla upp ett nytt nätverkssystem för företaget eller hemmet.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Har du i dagsläget ett etablerat företag eller vill du ha hjälp med att ta fram en teknisk lösning som passar just dig? Då finns vi alltid tillgängliga för att dela med oss om våra erfarenheter och råd. Vi gör en detaljerad analys av ditt företag för att ta fram den bästa lösningen för just er.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}