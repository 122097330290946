import meter1 from "../assets/img/Resurs 2.png";
import meter2 from "../assets/img/Resurs 2.png";
import meter3 from "../assets/img/Resurs 2.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Erfarenheter </h2>
                        <p>Behöver du hjälp med en krånglig dator eller vill du kanske bygga en ny dator? Då är du på rätt ställe. Vi sätter alltid kunden först och tillsammans med er skapar vi den bästa upplevelsen och produkten som passar precis dig. <br></br><br></br>Om du vill vara en del av framtiden tillsammans med oss på J.L Web, tveka inte att kontakta oss idag via formuläret nedan eller mejla till jessie@jlweb.se.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={meter1} alt="Image" />
                                <h5>Web Utveckling</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image" />
                                <h5>IT-Support</h5>
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Image" />
                                <h5>Web Design</h5>
                            </div>
                            <div className="item">
                                <img src={meter1} alt="Image" />
                                <h5>IT-Konsultion</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}