import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/JL Web.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { HashLink } from "react-router-hash-link";


export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(150);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Web Utvecklare", "IT-Support",  "IT-Konsult" ];
  const period = 1000;
  const isMobile = window.matchMedia("(max-width: 992px)").matches

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if(!isMobile){
      if (isDeleting) {
        setDelta(prevDelta => prevDelta / 2);
      }
  
      if (!isDeleting && updatedText === fullText) {
        setIsDeleting(true);
        setIndex(prevIndex => prevIndex - 1);
        setDelta(period);
      } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setIndex(1);
        setDelta(250);
      } else {
        setIndex(prevIndex => prevIndex + 1);
      }
    }
  }


  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              <div >
                <span className="tagline">J.L Web</span>
                <h1>{`Din `} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "IT-Support", "Web Utvecklare", "IT-Konsult" ]'><span className="wrap">{text}</span></span></h1><br></br><br></br>
                    <p>Vi på J.L Web jobbar för att du inte ska behöva krångla med saker som bara ska fungera. J.L Web grundades och hade sin första handling sent 2023. Vi har nu bestämt att göra en storsatsning för att ge er möjligheten att ha det så enkelt det ska vara. Vi tar fram skräddarsydda hemsidor från grunden eller byggd på till exempel Shopify.</p>
                        <a href="#connect"> <button onClick={() => console.log('connect')}>Ta en Kaffe med oss <ArrowRightCircle size={25} /></button></a>
              </div>
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
                <div >
                    <img src={headerImg} alt="Header Img"/>
                </div>
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}